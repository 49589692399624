const EndPoint = {
    loginApi: "https://api.lifeguidance.in/auth/login.php",
    studentRegisterApi: "https://api.lifeguidance.in/auth/studentRegister.php",
    studentsApi: "https://api.lifeguidance.in/auth/students.php",
    getBatchApi: "https://api.lifeguidance.in/auth/getBatches.php",
    updateBatchApi: "https://api.lifeguidance.in/auth/updateBatches.php",
    getMemberApi: "https://api.lifeguidance.in/auth/getMember.php",
    getCourseApi: "https://api.lifeguidance.in/auth/getCourse.php",
    updateGtSApi: "https://api.lifeguidance.in/auth/guestToStudent.php",
    insertBatchApi: "https://api.lifeguidance.in/auth/insertBatch.php",
    insertCourseApi: "https://api.lifeguidance.in/auth/insertCourse.php",
    updateCourseApi: "https://api.lifeguidance.in/auth/updateCourse.php",
    getFeesApi: "https://api.lifeguidance.in/auth/getFees.php",
    insertFeesApi: "https://api.lifeguidance.in/auth/insertFee.php",
    updateStudentStatusApi: "https://api.lifeguidance.in/auth/updateStudentStatus.php",
    insertOtherCourseApi: "https://api.lifeguidance.in/auth/insertOtherCourses.php",
    getOtherCourseApi: "https://api.lifeguidance.in/auth/getOtherCourses.php",
    getSubOtherCourse1Api: "https://api.lifeguidance.in/auth/getSubOtherCourse1.php",
    getSubOtherCourse2Api: "https://api.lifeguidance.in/auth/getSubOtherCourse2.php",
    getSubOtherCourse3Api: "https://api.lifeguidance.in/auth/getSubOtherCourse3.php",
    insertOtherSubCourse1Api: "https://api.lifeguidance.in/auth/insertOtherSubCourse1.php",
    insertOtherSubCourse2Api: "https://api.lifeguidance.in/auth/insertOtherSubCourse2.php",
    insertOtherSubCourse3Api: "https://api.lifeguidance.in/auth/insertOtherSubCourse3.php",
    getCertificatesApi: "https://api.lifeguidance.in/auth/getCertificates.php",
};

export default EndPoint;