import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUsers, FaBirthdayCake, FaBook, FaChalkboardTeacher, FaUserFriends } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';
import EndPoint from '../api/end_point';
import './dashboard.css'; // Import custom styles for Dashboard

// Utility functions
const getTodaysBirthdays = (students) => {
  const now = new Date();
  return students.filter(student => {
    const birthday = new Date(student.stu_dob);
    return (
      birthday.getDate() === now.getDate() &&
      birthday.getMonth() === now.getMonth()
    );
  });
};

const getCurrentYearStudents = (students) => {
  const now = new Date();
  const startDate = new Date(now.getFullYear(), 3, 31);
  const endDate = new Date(now.getFullYear() + 1, 3, 31);

  if (now.getMonth() < 4) {
    startDate.setFullYear(now.getFullYear() - 1);
    endDate.setFullYear(now.getFullYear());
  }

  return students.filter(student => {
    const admissionDate = new Date(student.stu_adm_date);
    return admissionDate > startDate && admissionDate < endDate;
  });
};

const Dashboard = () => {
  const [studentList, setStudentList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [batchList, setBatchList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [studentsResponse, coursesResponse, batchesResponse, membersResponse] = await Promise.all([
        axios.get(EndPoint.studentsApi),
        axios.get(EndPoint.getCourseApi),
        axios.get(EndPoint.getBatchApi),
        axios.get(EndPoint.getMemberApi),
      ]);

      setStudentList(studentsResponse.data);
      setCourseList(coursesResponse.data);
      setBatchList(batchesResponse.data);
      setMemberList(membersResponse.data);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  // Show the spinner if loading
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const todaysBirthdays = getTodaysBirthdays(studentList);

  // Main dashboard content once data is loaded
  return (
    <div className="container mt-5">
      <div className="row">
        <StatCard icon={<FaUsers size={30} />} title="Admissions" count={getCurrentYearStudents(studentList).length} color="bg-primary" />
        <StatCard icon={<FaBook size={30} />} title="Courses" count={courseList.length} color="bg-success" />
        <StatCard icon={<FaChalkboardTeacher size={30} />} title="Batches" count={batchList.length} color="bg-warning" />
        <StatCard icon={<FaUserFriends size={30} />} title="Teachers" count={memberList.length} color="bg-info" />
      </div>

      {/* New card for today's birthday students */}
      <div className="row mt-4">
        <StatCard icon={<FaBirthdayCake size={30} />} title="Today's Birthdays" count={todaysBirthdays.length} color="bg-birthday" />
      </div>

      {/* List of students with today's birthdays */}
      {todaysBirthdays.length > 0 && (
        <div className="row mt-3">
          {todaysBirthdays.map((student) => (
            <BirthdayCard key={student.stu_id} student={student} />
          ))}
        </div>
      )}
    </div>
  );
};

// BirthdayCard component
const BirthdayCard = ({ student }) => (
  <div className="col-md-4 mb-3">
    <div className="card-C shadow-sm birthday-card">
      <img
        src={student.stu_img}
        alt={`${student.stu_name}'s profile`}
        className="card-img-top cardImage"
      />
      <div className="card-body text-center">
        <h5 className="card-title">{student.stu_name}</h5>
        <p className="card-text">Birthday: {new Date(student.stu_dob).toLocaleDateString()}</p>
      </div>
    </div>
  </div>
);

// StatCard component
const StatCard = ({ icon, title, count, color }) => (
  <div className="col-md-3 mb-3">
    <div className={`card-C shadow-sm stat-card ${color}`}>
      <div className="d-flex align-items-center p-3">
        <div className="flex-shrink-0">
          {icon}
        </div>
        <div className="ms-3 text-white">
          <h3>{count}</h3>
          <p className="text-light">{title}</p>
        </div>
      </div>
    </div>
  </div>
);

export default Dashboard;
