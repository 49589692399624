import axios from 'axios';
import React, { useState, useEffect } from 'react';
import EndPoint from '../api/end_point';
import { Col, Row, Table, Modal, Button, Form, ProgressBar, Spinner, Alert } from 'react-bootstrap';
import certPDF from '../assets/certifcate_template/cert-a.pdf';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { format } from 'date-fns';

const ViewCertificates = () => {
    const [certificatesList, setCertificatesList] = useState([]);
    const [studentsList, setStudentsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showGeneCertModal, setShowGeneCertModal] = useState(false);
    const [selectGrade, setSelectGrade] = useState('');
    const [selectGradeError, setSelectGradeError] = useState('');
    const [verifyLGIDError, setVerifyLGIDError] = useState('');
    const [selectedIssueDateError, setSelectedIssueDateError] = useState('');
    const [selectedIssueDate, setSelectedIssueDate] = useState('');
    const [stuLgId, setStuLgId] = useState('');
    const [verifingLgId, setVerifingLgId] = useState(false);
    const [verifiedStudent, setVerifiedStudent] = useState(null);
    const [certificateAlreadyGenerated, setCertificateAlreadyGenerated] = useState(false); // New state for already generated certificates
    const [successMessage, setSuccessMessage] = useState('');
    const [certificatesNotFound, setCertificatesNotFound] = useState(false);

    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData(); // Fetch both certificates and students
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const [certResponse, studResponse, courseResponse] = await Promise.all([
                axios.get(EndPoint.getCertificatesApi),
                axios.get(EndPoint.studentsApi),
                axios.get(EndPoint.getCourseApi),
            ]);

            const certificates = Array.isArray(certResponse.data) ? certResponse.data : [];
            const students = Array.isArray(studResponse.data) ? studResponse.data : [];
            const courses = Array.isArray(courseResponse.data) ? courseResponse.data : [];

            const matchedCertificates = certificates.map(certificate => {
                const student = students.find(stu => stu.stu_lg_id === certificate.stu_lg_id);
                const course = courses.find(course => course.course_id === student?.stu_course);
                return {
                    ...certificate,
                    stu_name: student ? student.stu_name : 'Unknown Name',
                    stu_father_name: student ? student.stu_father_name : 'Unknown Name',
                    stu_course: course ? course.course_name : 'Unknown Course',
                };
            });

            setCertificatesList(matchedCertificates);

        } catch (err) {
            console.error("Error fetching data:", err.message || err);
        } finally {
            setLoading(false);
        }
    };

    const generateAndUploadPdf = async (stu_name, course_name, stu_father_name, stu_lg_id, stu_dob, stu_gender) => {
        let hasError = false;

        if (!selectGrade) {
            setSelectGradeError('Select Grade');
            hasError = true;
        }

        if (!selectedIssueDate) {
            setSelectedIssueDateError('Enter Date of Issue');
            hasError = true;
        }

        if (hasError) {
            return;
        }

        const maleOrFemale = () => {
            if (stu_gender === 'Male') {
                return 'Mr. ';
            } else if (stu_gender === 'Female') {
                return 'Miss ';
            }
            return '';
        };

        setLoading(true);
        setError(null);

        try {
            const existingPdfBytes = await fetch(certPDF).then(res => {
                if (!res.ok) {
                    throw new Error('PDF not found or unable to load');
                }
                return res.arrayBuffer();
            });

            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
            const firstPage = pdfDoc.getPages()[0];

            let courseDesc = "for successfully completing the " + course_name + " from Life Guidance Computer Institute (LGCI). Your dedication and effort have been exemplary, and we congratulate you on your achievement. \n \nFor more details visit our Website - www.lifeguidance.in | www.banideveloper.in"

            firstPage.drawText(maleOrFemale() + stu_name, { x: 100, y: 348, size: 18, color: rgb(0.04, 0.4, 0.61), font: helveticaFont });
            firstPage.drawText('Mr ' + stu_father_name, { x: 460, y: 348, size: 18, color: rgb(0.004, 0.38, 0.61), font: helveticaFont });
            firstPage.drawText(format(new Date(stu_dob), 'dd-MMM-yyyy'), { x: 125, y: 308, size: 18, color: rgb(0.004, 0.38, 0.61), font: helveticaFont });
            firstPage.drawText(selectGrade, { x: 500, y: 308, size: 18, color: rgb(0.004, 0.38, 0.61), font: helveticaFont });
            firstPage.drawText(courseDesc, { x: 60, y: 277, maxWidth: 700, size: 13, font: helveticaFont, lineHeight: 18, color: rgb(0.004, 0.38, 0.61) });
            firstPage.drawText("Certificate No.    : " + stu_lg_id, { x: 485, y: 525, size: 16, font: helveticaFont, color: rgb(0.004, 0.38, 0.61) });
            firstPage.drawText("Issue Date          : " + format(new Date(selectedIssueDate), 'MMM-dd-yyyy'), { x: 485, y: 505, size: 16, font: helveticaFont, lineHeight: 14, color: rgb(0.004, 0.38, 0.61) });

            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });

            const formData = new FormData();
            formData.append('certificate', blob, '.pdf');
            formData.append('stu_lg_id', stu_lg_id);

            const response = await axios.post('https://api.lifeguidance.in/auth/generateCert.php', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.status === 200 && response.data.success) {
                setSuccessMessage('Certificate generated and uploaded successfully!'); // Display success message
                setSelectGrade('');
                setStuLgId('');  // Clear the fields
                setSelectedIssueDate('');
                setVerifiedStudent(null);  // Reset student data
                setShowGeneCertModal(false);
            } else {
                setError(response.data.message || 'Failed to upload the certificate.');
            }
        } catch (err) {
            console.error('Error creating or uploading PDF:', err);
            setError('An unexpected error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const verifyStudent = async () => {
        clearModal();
        setVerifingLgId(true);
        setVerifyLGIDError('');

        if (!stuLgId) {
            setVerifyLGIDError('Enter Student LG Id to Verify');
            setVerifingLgId(false);
            return;
        }

        // Check if certificate is already generated
        const existingCertificate = certificatesList.find(certificate => certificate.stu_lg_id === stuLgId);
        if (existingCertificate) {
            setCertificateAlreadyGenerated(true); // Set certificate generated flag
            setVerifingLgId(false);
            return;
        }

        setCertificateAlreadyGenerated(false); // Reset the flag if not found

        try {
            const [studResponse, courseResponse] = await Promise.all([
                axios.get(EndPoint.studentsApi),
                axios.get(EndPoint.getCourseApi),
            ]);

            const students = Array.isArray(studResponse.data) ? studResponse.data : [];
            const courses = Array.isArray(courseResponse.data) ? courseResponse.data : [];

            const student = students.find(stu => stu.stu_lg_id === stuLgId);
            if (student) {
                setCertificatesNotFound(false);
                const course = courses.find(course => course.course_id === student?.stu_course);

                setVerifiedStudent({
                    ...student,
                    stu_course: course ? course.course_name : 'Unknown Course',
                });
            } else {
                setCertificatesNotFound(true);
            }
        } catch (err) {
            console.error('Error verifying student:', err.message || err);
        } finally {
            setVerifingLgId(false);
        }
    };

    const viewCertificate = (pdfUrl) => {
        const printWindow = window.open(pdfUrl, '_blank');
        printWindow.print();
    };


    const clearModal = () => {
        setSelectGrade('');
        setSelectedIssueDate('');
        setVerifiedStudent(null);
        setCertificateAlreadyGenerated(false);
    }
    const closeModal = () => {
        setShowGeneCertModal(false);
        setSelectGrade('');
        setStuLgId('');
        setSelectedIssueDate('');
        setVerifiedStudent(null);
        setCertificateAlreadyGenerated(false);
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>Certificates List</h1>
                <div>
                    <Button className='me-2' onClick={() => setShowGeneCertModal(true)}>
                        Generate Certificate
                    </Button>
                    <Button variant="success" onClick={fetchData} disabled={loading}>
                        {loading ? 'Refreshing...' : 'Refresh'}
                    </Button>
                </div>
            </div>
            <hr />

            {successMessage && <Alert variant="success" dismissible onClose={() => setSuccessMessage("")}>
                {successMessage}
            </Alert>}
            {error && <Alert variant="danger">{error}</Alert>}

            {certificatesList.length > 0 ? (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Certificate No</th>
                            <th>Student Name</th>
                            <th>Father Name</th>
                            <th>Student Course</th>
                            <th>Issue Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {certificatesList.slice().reverse().map((certificate, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{certificate.stu_lg_id}</td>
                                <td>{certificate.stu_name}</td>
                                <td>{certificate.stu_father_name}</td>
                                <td>{certificate.stu_course}</td>
                                <td>{certificate.uploaded_at}</td>
                                <td>
                                    <Button variant="info" onClick={() => viewCertificate(certificate.file_path)}>
                                        View
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <p>No certificates found.</p>
            )}

            <Modal show={showGeneCertModal} onHide={() => {
                setShowGeneCertModal(false);
                setSelectGrade('');
                setStuLgId('');
                setSelectedIssueDate('');
                setVerifiedStudent(null);
                setCertificateAlreadyGenerated(false); // Reset when modal closes
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Generate Certificate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formLgId">
                            <Form.Label>Enter Student LG ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter LG ID"
                                value={stuLgId}
                                onChange={(e) => setStuLgId(e.target.value)}
                            />
                            {verifyLGIDError && <p className="text-danger">{verifyLGIDError}</p>}
                            <Button className='mt-2' onClick={verifyStudent} disabled={verifingLgId}>
                                {verifingLgId ? 'Verifying...' : 'Verify Student'}
                            </Button>
                        </Form.Group>

                        {certificateAlreadyGenerated && (
                            <p className="text-warning">Certificate Already Generated</p>
                        )}

                        {certificatesNotFound && (
                            <p className="text-danger">LG ID not Found</p>
                        )}

                        {verifiedStudent && !certificateAlreadyGenerated && (
                            <>
                                <p>Student Name: {verifiedStudent.stu_name}</p>
                                <p>Father's Name: {verifiedStudent.stu_father_name}</p>
                                <p>Course: {verifiedStudent.stu_course}</p>

                                <Form.Group controlId="formGrade">
                                    <Form.Label>Select Grade</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={selectGrade}
                                        onChange={(e) => setSelectGrade(e.target.value)}
                                    >
                                        <option value="">Select Grade</option>
                                        <option value="S">S (91% - 100%)</option>
                                        <option value="A+">A+ (80% - 90%)</option>
                                        <option value="A">A (68% - 79%)</option>
                                        <option value="B">B (56% - 67%)</option>
                                        <option value="C">C (42% - 55%)</option>
                                    </Form.Control>
                                    {selectGradeError && <p className="text-danger">{selectGradeError}</p>}
                                </Form.Group>

                                <Form.Group controlId="formIssueDate">
                                    <Form.Label>Issue Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={selectedIssueDate}
                                        onChange={(e) => setSelectedIssueDate(e.target.value)}
                                    />
                                    {selectedIssueDateError && <p className="text-danger">{selectedIssueDateError}</p>}
                                </Form.Group>
                            </>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => closeModal()}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => generateAndUploadPdf(
                            verifiedStudent?.stu_name,
                            verifiedStudent?.stu_course,
                            verifiedStudent?.stu_father_name,
                            stuLgId,
                            verifiedStudent?.stu_dob,
                            verifiedStudent?.stu_gender
                        )}
                        disabled={certificateAlreadyGenerated || loading}
                    >
                        {loading ? 'Generating...' : 'Generate Certificate'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ViewCertificates;