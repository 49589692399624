import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import EndPoint from '../../api/end_point';
import logolGCI from '../../assets/lgci.png';

const Login = () => {
    const [lgid, setLgid] = useState('');
    const [password, setPassword] = useState('');
    const [isLogin, setIsLogin] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async () => {
        if (!lgid || !password) {
            alert('Please enter LG ID and password');
            return;
        }

        setIsLogin(true);

        try {
            // Form data needs to be encoded for PHP
            const formData = new URLSearchParams();
            formData.append('lg_id', lgid);
            formData.append('password', password);

            const response = await axios.post(EndPoint.loginApi, formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // Setting the content type as URL-encoded
                },
            });

            if (response.status === 200 && response.data.status) {
                alert(response.data.message);
                localStorage.setItem("authToken", "aabidhusain-token-api");
                navigate('/homepage'); // Redirect after login
            } else {
                alert(response.data.message || 'Login failed');
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('An error occurred. Please try again.');
        } finally {
            setIsLogin(false);
        }
    };

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-6 d-flex align-items-center justify-content-center">
                    <img src={logolGCI} alt="LGCI Logo" className="img-fluid" />
                </div>
                <div className="col-md-6">
                    <div className="card p-5 shadow">
                        <h2 className="mb-3 text-center">Login Panel</h2>
                        <p className="text-center">Only for admins</p>
                        <div className="mb-3">
                            <label htmlFor="lgid" className="form-label">Enter your LG ID</label>
                            <input
                                type="text"
                                className="form-control"
                                id="lgid"
                                value={lgid}
                                onChange={(e) => setLgid(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Enter your Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="text-center">
                            <button className="btn btn-primary w-100" onClick={handleLogin} disabled={isLogin}>
                                {isLogin ? <div className="spinner-border spinner-border-sm" role="status"></div> : 'Login'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;