// src/Course.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import EndPoint from '../../api/end_point';
import './course.css';

// Predefined lists for duration, level, and category
const _selectDuration = [
  "1 Month", "2 Months", "3 Months", "4 Months", "5 Months", "6 Months", "8 Months",
  "10 Months", "12 Months", "18 Months", "24 Months", "With Course"
];

const _selectLevel = ["Beginner", "Intermediate", "Advanced", "Professional"];

const _selectCategory = [
  "Basic Course", "Intermediate Course", "Programming Language", "Software Development",
  "Accounted", "Web Development and Design", "Database Management", "DSA", "Cyber security",
  "Networking", "AI and ML", "Data Science and Analytics", "Computer Hardware and Maintenance",
  "Mobile App Development", "Game Development", "Hybrid Platform Development",
  "Certificate Course", "Video/Audio Editing Course"
];

const Course = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  // Fields for editing
  const [courseName, setCourseName] = useState('');
  const [courseDesc, setCourseDesc] = useState('');
  const [coursePrice, setCoursePrice] = useState('');
  const [courseTeacherName, setCourseTeacherName] = useState('');
  const [courseDuration, setCourseDuration] = useState('');
  const [courseLevel, setCourseLevel] = useState('');
  const [courseCat, setCourseCat] = useState('');
  const [courseImg, setCourseImg] = useState(null); // For image upload
  const [imgStatus, setImgStatus] = useState('0'); // To track if image is updated

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const response = await axios.get(EndPoint.getCourseApi);
        setCourses(response.data);
      } catch (err) {
        setError(err.message); // Set error if API call fails
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  const handleEditClick = (course) => {
    setSelectedCourse(course);
    setCourseName(course.course_name);
    setCourseDesc(course.course_desc);
    setCoursePrice(course.course_price);
    setCourseTeacherName(course.course_teacher_name);
    setCourseDuration(course.course_duration);
    setCourseLevel(course.course_level);
    setCourseCat(course.course_cat);
    setCourseImg(null); // Reset the image file
    setImgStatus('0'); // No image change by default
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'course_img') {
      setCourseImg(files[0]);
      setImgStatus('1'); // Image is being updated
    } else {
      switch (name) {
        case 'course_name': setCourseName(value); break;
        case 'course_desc': setCourseDesc(value); break;
        case 'course_price': setCoursePrice(value); break;
        case 'course_teacher_name': setCourseTeacherName(value); break;
        case 'course_duration': setCourseDuration(value); break;
        case 'course_level': setCourseLevel(value); break;
        case 'course_cat': setCourseCat(value); break;
        default: break;
      }
    }
  };

  const handleSaveChanges = async () => {
    const formData = new FormData();
    formData.append('course_id', selectedCourse.id);
    formData.append('course_name', courseName);
    formData.append('course_desc', courseDesc);
    formData.append('course_price', coursePrice);
    formData.append('course_teacher_name', courseTeacherName);
    formData.append('course_duration', courseDuration);
    formData.append('course_level', courseLevel);
    formData.append('course_cat', courseCat);
    formData.append('img_status', imgStatus);

    if (imgStatus === '1' && courseImg) {
      formData.append('course_img', courseImg);
      formData.append('img_name', `${selectedCourse.id}_${courseImg.name}`);
    }

    try {
      const response = await axios.post(EndPoint.updateCourseApi, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.data.status === 1) {
        alert('Course updated successfully!');
        // Refetch courses after successful update
        const updatedCourses = await axios.get(EndPoint.getCourseApi);
        setCourses(updatedCourses.data);
      } else {
        alert('Failed to update course');
      }
    } catch (err) {
      console.error('Error updating course:', err);
      alert('Error updating course');
    }

    setShowModal(false); // Close modal after saving
    resetModalState();
  };

  // Reset modal state to prevent data from being reused
  const resetModalState = () => {
    setSelectedCourse(null);
    setCourseName('');
    setCourseDesc('');
    setCoursePrice('');
    setCourseTeacherName('');
    setCourseDuration('');
    setCourseLevel('');
    setCourseCat('');
    setCourseImg(null);
    setImgStatus('0');
  };

  return (
    <Container className="mt-4">
      {loading && <Spinner animation="border" />}
      {error && <Alert variant="danger">{error}</Alert>}
      {!loading && courses.length === 0 && <p>No courses available.</p>}

      <Row>
        {courses.map((course, index) => (
          <Col md={4} key={course.id + index} className="mb-4">
            <Card>
              <Card.Img
                variant="top"
                src={course.course_img}
                alt={course.course_name}
                style={{ height: '200px', objectFit: 'cover' }}
              />
              <Card.Body>
                <Card.Title className='one-line-desc'>{course.course_name}</Card.Title>
                <Card.Text className='two-line-desc'>
                  {course.course_desc}
                </Card.Text>
                <Card.Footer>
                  <small className="text-muted">Duration: {course.course_duration}</small>
                </Card.Footer>
                <Button variant="primary" className="mt-2" onClick={() => handleEditClick(course)}>
                  Edit Details
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Modal for editing course details */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Course Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCourseName">
              <Form.Label>Course Name</Form.Label>
              <Form.Control
                type="text"
                name="course_name"
                value={courseName}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formCourseDesc">
              <Form.Label>Course Description</Form.Label>
              <Form.Control
                type="text"
                name="course_desc"
                value={courseDesc}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formCoursePrice">
              <Form.Label>Course Price</Form.Label>
              <Form.Control
                type="text"
                name="course_price"
                value={coursePrice}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formCourseTeacher">
              <Form.Label>Course Teacher Name</Form.Label>
              <Form.Select
                name="course_teacher_name"
                value={courseTeacherName}
                onChange={handleInputChange}
              >
                <option value={'LG00000001'}>Aabid Husain</option>
                <option value={'LG00000003'}>Shailendra Sir</option>
                <option value={'LG00000004'}>Jay Yadav</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formCourseDuration">
              <Form.Label>Course Duration</Form.Label>
              <Form.Select
                name="course_duration"
                value={courseDuration}
                onChange={handleInputChange}
              >
                {_selectDuration.map((duration, index) => (
                  <option key={index} value={duration}>{duration}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formCourseLevel">
              <Form.Label>Course Level</Form.Label>
              <Form.Select
                name="course_level"
                value={courseLevel}
                onChange={handleInputChange}
              >
                {_selectLevel.map((level, index) => (
                  <option key={index} value={level}>{level}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formCourseCat">
              <Form.Label>Course Category</Form.Label>
              <Form.Select
                name="course_cat"
                value={courseCat}
                onChange={handleInputChange}
              >
                {_selectCategory.map((category, index) => (
                  <option key={index} value={category}>{category}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formCourseImg">
              <Form.Label>Course Image (optional)</Form.Label>
              <Form.Control
                type="file"
                name="course_img"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowModal(false); resetModalState(); }}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Course;