import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Alert, Card, Modal } from 'react-bootstrap';
import EndPoint from '../../api/end_point';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const AddStudent = () => {
    const [image, setImage] = useState(null);
    const [imageShow, setImageShow] = useState(null);
    const [isRegistering, setIsRegistering] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedAdmDate, setSelectedAdmDate] = useState(null);
    const [alertMessage, setAlertMessage] = useState('');

    const [coursesList, setCoursesList] = useState([]);
    const [batchList, setBatchList] = useState([]);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [lgId, setLgId] = useState(null); // New state for LG ID

    const [formData, setFormData] = useState({
        stuName: '',
        stuGender: '',
        stuFatherName: '',
        stuMotherName: '',
        stuAdhar: '',
        stuMoNo: '',
        stuAddress: '',
        stuCategory: '',
        stuPinCode: '',
        selectedCourse: '',
        selectedBatchId: '',
        selectedTeacher: '',
        selectedNationality: '',
        selectedReligion: '',
        selectedQualification: ''
    });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const courses = await axios.get(EndPoint.getCourseApi);
            const batches = await axios.get(EndPoint.getBatchApi);
            setCoursesList(courses.data);
            setBatchList(batches.data);
        };
        fetchData();
    }, []);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageShow(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
        navigate('/homepage/students');
    };

    const validateForm = () => {
        if (!image) return "Select Image";
        if (!formData.stuName) return "Enter Student name";
        if (formData.stuName.trim().length < 4) return "Student name should be at least 4 characters";
        if (!formData.stuGender) return "Select Gender";
        if (!selectedDate) return "Enter Date of Birth";
        if (!formData.stuFatherName) return "Enter Student Father Name";
        if (!formData.stuMotherName) return "Enter Student Mother Name";
        if (!formData.stuAddress) return "Enter Permanent Address";
        if (formData.stuAddress.trim().length < 16) return "Permanent Address must include all details";
        if (!formData.selectedCourse) return "Select Course";
        if (!formData.stuAdhar) return "Enter Student Adhar No";
        if (formData.stuAdhar.trim().length !== 12) return "Enter correct student Adhar No";
        if (!formData.stuMoNo) return "Enter Mobile No";
        if (formData.stuMoNo.trim().length !== 10) return "Enter Correct Mobile No";
        if (!formData.selectedNationality) return "Select your Nationality";
        if (!formData.selectedReligion) return "Select your Religion";
        if (!formData.stuCategory) return "Enter your Category";
        if (!formData.selectedQualification) return "Select your Qualification";
        if (!formData.selectedBatchId) return "Select your Batch Id";
        if (!formData.stuPinCode) return "Enter Your Pin Code";
        if (formData.stuPinCode.trim().length !== 6) return "Enter Correct Pin Code";
        if (!selectedAdmDate) return "Select Admission Date";
        if (!formData.selectedTeacher) return "Select Referred Teacher";
        return null;
    };

    const registerStudent = async (e) => {
        e.preventDefault();
        setIsRegistering(true);
        setAlertMessage('');

        const error = validateForm();
        if (error) {
            setIsRegistering(false);
            return setAlertMessage(error);
        }

        const apiUrl = EndPoint.studentRegisterApi; // Replace with your actual API endpoint
        const formDataToSend = new FormData();
        formDataToSend.append('stu_name', formData.stuName);
        formDataToSend.append('stu_father_name', formData.stuFatherName);
        formDataToSend.append('stu_mother_name', formData.stuMotherName);
        formDataToSend.append('stu_adhar', formData.stuAdhar);
        formDataToSend.append('stu_dob', selectedDate);
        formDataToSend.append('stu_gender', formData.stuGender);
        formDataToSend.append('stu_mo_no', formData.stuMoNo);
        formDataToSend.append('stu_course', formData.selectedCourse);
        formDataToSend.append('stu_religion', formData.selectedReligion);
        formDataToSend.append('stu_address', formData.stuAddress);
        formDataToSend.append('stu_category', formData.stuCategory);
        formDataToSend.append('stu_nationality', formData.selectedNationality);
        formDataToSend.append('stu_batch_id', formData.selectedBatchId);
        formDataToSend.append('stu_pin_code', formData.stuPinCode);
        formDataToSend.append('stu_education', formData.selectedQualification);
        formDataToSend.append('stu_adm_date', selectedAdmDate);
        formDataToSend.append('adm_by', formData.selectedTeacher);
        if (image) {
            formDataToSend.append('stu_img', image);
        }

        try {
            const response = await axios.post(apiUrl, formDataToSend);
            if (response.status === 200) {
                setLgId(response.data.stu_lg_id); // Assuming API returns the LG ID
                setIsRegistering(false);
                setShowSuccessModal(true); // Show success modal
            } else {
                setIsRegistering(false);
                setAlertMessage('Failed to Update');
            }
        } catch (error) {
            setIsRegistering(false);
            setAlertMessage(`Error: ${error.message}`);
        }
    };

    return (
        <Container className="mt-4">
            <h2 className="text-center mb-4">Add Student</h2>
            {alertMessage && <Alert variant="danger">{alertMessage}</Alert>}
            <Form onSubmit={registerStudent}>
                <Card className="mb-3">
                    <Card.Header>Personal Information</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="stuName">
                                    <Form.Label>Student Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="stuName"
                                        value={formData.stuName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="stuGender">
                                    <Form.Label>Gender:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="stuGender"
                                        value={formData.stuGender}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="stuDob">
                                    <Form.Label>Date of Birth:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="stuImage">
                                    <Form.Label>Upload Image:</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={handleImageChange}
                                        accept="image/*"
                                        required
                                    />
                                    {imageShow && (
                                        <img src={imageShow} alt="Preview" style={{ maxWidth: '100px', marginTop: '10px' }} />
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Card className="mb-3">
                    <Card.Header>Family Information</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="stuFatherName">
                                    <Form.Label>Father's Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="stuFatherName"
                                        value={formData.stuFatherName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="stuMotherName">
                                    <Form.Label>Mother's Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="stuMotherName"
                                        value={formData.stuMotherName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="stuMoNo">
                                    <Form.Label>Mobile Number:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="stuMoNo"
                                        value={formData.stuMoNo}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="stuAdhar">
                                    <Form.Label>Aadhar Number:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="stuAdhar"
                                        value={formData.stuAdhar}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="stuCategory">
                                    <Form.Label>Category:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="stuCategory"
                                        value={formData.stuCategory}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="stuPinCode">
                                    <Form.Label>Pin Code:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="stuPinCode"
                                        value={formData.stuPinCode}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Card className="mb-3">
                    <Card.Header>Address Information</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="stuAddress">
                                    <Form.Label>Permanent Address:</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="stuAddress"
                                        value={formData.stuAddress}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Card className="mb-3">
                    <Card.Header>Additional Information</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="selectedCourse">
                                    <Form.Label>Course:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="selectedCourse"
                                        value={formData.selectedCourse}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Course</option>
                                        {coursesList.map((course) => (
                                            <option key={course.course_id} value={course.course_id}>
                                                {course.course_name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="selectedBatchId">
                                    <Form.Label>Batch ID:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="selectedBatchId"
                                        value={formData.selectedBatchId}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Batch</option>
                                        {batchList.map((batch) => (
                                            <option key={batch.batch_id} value={batch.batch_id}>
                                                {batch.batch_name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="selectedAdmDate">
                                    <Form.Label>Admission Date:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        onChange={(e) => setSelectedAdmDate(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="selectedNationality">
                                    <Form.Label>Nationality:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="selectedNationality"
                                        value={formData.selectedNationality}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Nationality</option>
                                        <option value="Indian">Indian</option>
                                        <option value="Nepali">Nepali</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="selectedReligion">
                                    <Form.Label>Religion:</Form.Label>
                                    <Form.Control
                                        as="select" // Change this to a select dropdown
                                        name="selectedReligion"
                                        value={formData.selectedReligion}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Religion</option>
                                        <option value="Hinduism">Hinduism</option>
                                        <option value="Islam">Islam</option>
                                        <option value="Christianity">Christianity</option>
                                        <option value="Sikhism">Sikhism</option>
                                        <option value="Buddhism">Buddhism</option>
                                        <option value="Jainism">Jainism</option>
                                        <option value="Zoroastrianism">Zoroastrianism</option>
                                        <option value="Judaism">Judaism</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="selectedQualification">
                                    <Form.Label>Qualification:</Form.Label>
                                    <Form.Control
                                        as="select" // Set as a select dropdown
                                        name="selectedQualification"
                                        value={formData.selectedQualification}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Qualification</option>
                                        <option value="Bachelor's Degree in CS or related field">Bachelor's Degree in CS or related field</option>
                                        <option value="Master's Degree in CS or related field">Master's Degree in CS or related field</option>
                                        <option value="Diploma in CS or Software Engineering">Diploma in CS or Software Engineering</option>
                                        <option value="Associate Degree in Information Technology">Associate Degree in Information Technology</option>
                                        <option value="Higher Secondary (12th Grade) in Math and CS">Higher Secondary (12th Grade) in Math and CS</option>
                                        <option value="High School in Math and CS">High School in Math and CS</option>
                                        <option value="9th Pass">9th Pass</option>
                                        <option value="8th Pass">8th Pass</option>
                                        <option value="7th Pass">7th Pass</option>
                                        <option value="6th Pass">6th Pass</option>
                                        <option value="5th Pass">5th Pass</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="selectedTeacher">
                                    <Form.Label>Referred By:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="selectedTeacher"
                                        value={formData.selectedTeacher}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Button className="w-100" type="submit" disabled={isRegistering}>
                    {isRegistering ? 'Registering...' : 'Register Student'}
                </Button>
            </Form>
            {/* Success Modal */}
            <Modal show={showSuccessModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Student registered successfully!
                    <br />
                    LG ID: {lgId} {/* Display the LG ID */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default AddStudent;