import React, { useEffect, useState } from 'react';
import { Table, Container, Spinner, Alert, Modal, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import EndPoint from '../../api/end_point'; // Update this as needed for the dropdown data API
import './fees.css';

const Fees = () => {
    const [students, setStudents] = useState([]);
    const [fees, setFees] = useState([]);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showFeesModal, setShowFeesModal] = useState(false);
    const [showAddFeeModal, setShowAddFeeModal] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [selectedStudentFees, setSelectedStudentFees] = useState([]);

    const [feeAmount, setFeeAmount] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]); // State for dropdown options
    const [selectedOption, setSelectedOption] = useState(''); // State for selected dropdown option
    const [paymentDate, setPaymentDate] = useState('');

    const [searchQuery, setSearchQuery] = useState(''); // For combined search

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch students, fees, and courses data simultaneously
                const [studentsData, feesData, coursesData] = await Promise.all([
                    axios.get(EndPoint.studentsApi),
                    axios.get(EndPoint.getFeesApi),
                    axios.get(EndPoint.getCourseApi),
                ]);

                setStudents(studentsData.data);
                setFees(feesData.data);
                setCourses(coursesData.data);
            } catch (err) {
                setError('Error fetching data. Please try again later.');
            } finally {
                setLoading(false); // Move loading state update to finally
            }
        };

        fetchData();
        fetchDropdownOptions(); // Fetch dropdown options on component mount
    }, []);


    const fetchAllData = async () => {
        try {
            const [studentsData, feesData, coursesData] = await Promise.all([
                axios.get(EndPoint.studentsApi),
                axios.get(EndPoint.getFeesApi),
                axios.get(EndPoint.getCourseApi),
            ]);

            setStudents(studentsData.data);
            setFees(feesData.data);
            setCourses(coursesData.data);
        } catch (err) {
            setError('Error fetching data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    // Fetch dropdown options from the API
    const fetchDropdownOptions = async () => {
        try {
            const response = await axios.get(EndPoint.getMemberApi); // Replace with your actual dropdown API endpoint
            setDropdownOptions(response.data);
        } catch (err) {
            console.error('Error fetching dropdown options:', err);
        }
    };

    // Get student name by stu_lg_id
    const getStudentName = (stu_lg_id) => {
        const student = students.find((s) => s.stu_lg_id === stu_lg_id);
        return student ? student.stu_name : 'N/A';
    };

    // Get student image by stu_lg_id
    const getStudentImage = (stu_lg_id) => {
        const student = students.find((s) => s.stu_lg_id === stu_lg_id);
        return student ? student.stu_img : ''; // Replace 'stu_img' with the actual field name for the image
    };

    const getCourseDetails = (stu_lg_id) => {
        const student = students.find((s) => s.stu_lg_id === stu_lg_id);
        return student ? courses.find((course) => course.course_id === student.stu_course) : {};
    };

    const getRowClass = (status) => {
        switch (status) {
            case '0': return 'table-active'; // Active
            case '1': return 'table-inactive'; // Inactive
            case '2': return 'table-suspended'; // Suspended
            case '3': return 'table-complete'; // Complete
            case '4': return 'table-transferred'; // Transferred
            default: return 'red';
        }
    };

    // Handle click on "View All Fees" button to show fees for the selected student
    const handleViewFeesClick = (stu_lg_id) => {
        const studentFees = fees.filter(fee => fee.stu_lg_id === stu_lg_id);
        setSelectedStudent(stu_lg_id);
        setSelectedStudentFees(studentFees);
        setShowFeesModal(true);
    };

    const handleCloseModal = () => {
        setShowFeesModal(false);
        setShowAddFeeModal(false);
        setSelectedStudent(null);
        setSelectedStudentFees([]);
        setFeeAmount('');
        setPaymentMethod('');
        setSelectedOption('');
        setPaymentDate('');
    };

    // Handle click on "Add Fee" button
    const handleAddFeeClick = (stu_lg_id) => {
        setSelectedStudent(stu_lg_id);
        setShowAddFeeModal(true);
    };

    // Function to format date to "dd-MMM-yyyy"
    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options).replace(/(\d{2}) (\w{3}) (\d{4})/, '$1-$2-$3');
    };

    // Calculate total paid fees for the student
    const getTotalPaidFees = () => {
        return selectedStudentFees.reduce((total, fee) => total + parseFloat(fee.amount), 0);
    };

    // Get course fee and calculate remaining amount
    const getRemainingFees = () => {
        const courseDetails = getCourseDetails(selectedStudent);
        const courseFee = courseDetails ? parseFloat(courseDetails.course_price) : 0;
        const totalPaidFees = getTotalPaidFees();
        return courseFee - totalPaidFees;
    };

    // Handle fee submission (you can replace this with an API call)
    const handleFeeSubmit = async () => {
        // Create a FormData object
        const formData = new FormData();

        // Append each field to the FormData object
        formData.append('stu_lg_id', selectedStudent);
        formData.append('amount', feeAmount);
        formData.append('fee_type', paymentMethod);
        formData.append('teacher_lg_id', selectedOption);
        formData.append('fee_date', paymentDate);

        try {
            // Send the fee data to the server via API using FormData
            const response = await axios.post(EndPoint.insertFeesApi, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                console.log('Fee submitted successfully:', response.data);
                const updatedFeesData = await axios.get(EndPoint.getFeesApi);
                setFees(updatedFeesData.data);
                fetchAllData();
            } else {
                console.error('Error submitting fee:', response);
            }
        } catch (err) {
            console.error('Error submitting fee:', err);
        }

        console.log('Fee submitted:', formData);

        handleCloseModal();
    };

    // Filter the fees data based on search query
    const filteredFees = fees.filter((fee) => {
        const studentName = getStudentName(fee.stu_lg_id).toLowerCase();
        return (
            fee.stu_lg_id.includes(searchQuery) || studentName.includes(searchQuery.toLowerCase())
        );
    });

    if (loading) {
        return (
            <Container className="text-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <Container>
            <h2 className="my-4">Fees Dashboard</h2>

            {/* Search Filter */}
            <Form>
                <Form.Group controlId="searchQuery">
                    <Form.Label>Search by LG ID or Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter LG ID or Name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </Form.Group>
            </Form>

            {/* Scrollable Table Container */}
            <div className='mt-4' style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>LG ID</th>
                            <th>Fee Amount</th>
                            <th>Course</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredFees.slice().reverse().map((fee, index) => {
                            const courseDetails = getCourseDetails(fee.stu_lg_id);
                            const studentName = getStudentName(fee.stu_lg_id);
                            const studentImage = getStudentImage(fee.stu_lg_id);

                            return (
                                <tr key={fee.sr_no} className={getRowClass(fee.stu_status)}>
                                    <td>{fee.fee_date ? formatDate(fee.fee_date) : 'N/A'}</td>
                                    <td>
                                        {studentImage && (
                                            <img
                                                src={studentImage}
                                                alt={studentName}
                                                style={{ width: '50px', cursor: 'pointer' }}
                                            />
                                        )}
                                    </td>
                                    <td>{studentName}</td>
                                    <td>{fee.stu_lg_id}</td>
                                    <td>₹ {fee.amount}</td>
                                    <td>{courseDetails.course_name || 'N/A'}</td>
                                    <td>
                                        <Button variant="primary" onClick={() => handleAddFeeClick(fee.stu_lg_id)}>
                                            Add Fee
                                        </Button>{' '}
                                        <Button variant="info" onClick={() => handleViewFeesClick(fee.stu_lg_id)}>
                                            View Fees
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>

            {/* Modal to view all fees */}
            <Modal show={showFeesModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Total Fee : {getStudentName(selectedStudent)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Total Paid: ₹ {getTotalPaidFees()}</h5>
                    <h5>Remaining: ₹ {getRemainingFees()}</h5>

                    {selectedStudentFees.length > 0 ? (
                        <Table striped bordered hover>
                            <thead>
                                <tr key={'selectedStudentFees'}>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Payment Method</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedStudentFees.slice().reverse().map((fee, index) => (
                                    <tr key={fee.fee_id}>
                                        <td>{index + 1}</td>
                                        <td>{fee.fee_date ? formatDate(fee.fee_date) : 'N/A'}</td>
                                        <td>₹ {fee.amount}</td>
                                        <td>{fee.fee_type}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <Alert variant="info">No fees found for this student.</Alert>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal to add a new fee */}
            <Modal show={showAddFeeModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Fee for Student {getStudentName(selectedStudent)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="feeAmount">
                            <Form.Label>Fee Amount</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter fee amount"
                                value={feeAmount}
                                onChange={(e) => setFeeAmount(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="paymentMethod">
                            <Form.Label>Payment Type</Form.Label>
                            <Form.Select
                                value={paymentMethod}
                                onChange={(e) => setPaymentMethod(e.target.value)}
                            >
                                <option value="">Select Payment Type</option>
                                <option value="Cash">Cash</option>
                                <option value="Online">Online</option>
                                <option value="Concession">Concession</option>
                                <option value="Discount">Discount</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="dropdown">
                            <Form.Label>Select Teacher</Form.Label>
                            <Form.Select
                                value={selectedOption}
                                onChange={(e) => setSelectedOption(e.target.value)}
                            >
                                <option value="">Select an option</option>
                                {dropdownOptions.map((option) => (
                                    <option value={option.lg_id}>
                                        {option.fullName}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="paymentDate">
                            <Form.Label>Payment Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={paymentDate}
                                onChange={(e) => setPaymentDate(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleFeeSubmit}>
                        Submit Fee
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default Fees;