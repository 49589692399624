import React, { useState, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { Container, Row, Col, Card, Table, Modal, Button, Form, ProgressBar } from 'react-bootstrap'; // Import ProgressBar
import EndPoint from '../../api/end_point';
import axios from 'axios';
import './students.css';


const Students = () => {
    const [studentList, setStudentList] = useState([]);
    const [coursesList, setCoursesList] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [feeList, setFeeList] = useState([]);
    const [selectedStuLgId, setSelectedStuLgId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [admissionDate, setAdmissionDate] = useState('');
    const [genderFilter, setGenderFilter] = useState('All');
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true); // New state for loading
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');

    // add fee
    const [showAddFeeModal, setShowAddFeeModal] = useState(false);
    const [feeAmount, setFeeAmount] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]); // State for dropdown options
    const [selectedOption, setSelectedOption] = useState(''); // State for selected dropdown option
    const [paymentDate, setPaymentDate] = useState('');
    const [feeAmountError, setFeeAmountError] = useState('');
    const [paymentMethodError, setPaymentMethodError] = useState('');
    const [selectedOptionError, setSelectedOptionError] = useState('');
    const [paymentDateError, setPaymentDateError] = useState('');

    

    const handleEditClick = () => {
        const selectedStudent = studentList.find(stu => stu.stu_lg_id === selectedStuLgId);
        setSelectedStatus(selectedStudent.stu_status);
        setShowEditDialog(true);
    };


    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
    };

    const handleUpdateDetails = () => {
        // Ensure both parameters are set
        if (!selectedStuLgId || !selectedStatus) {
            console.error('Both student ID and status must be selected.');
            return; // Exit the function if parameters are not set
        }

        // Create a FormData object
        const formData = new FormData();
        formData.append('stu_lg_id', selectedStuLgId);
        formData.append('stu_status', selectedStatus);

        // Log the data being sent
        console.log('Data being sent to API:', Object.fromEntries(formData.entries()));

        setLoading(true); // Start loading

        axios.post(EndPoint.updateStudentStatusApi, formData, {
            headers: {
                'Content-Type': 'multipart/form-data' // Set the content type to multipart/form-data
            }
        })
            .then(response => {
                if (response.data && response.data.status === 'success') {
                    console.log('Status updated successfully:', response.data);
                    setShowEditDialog(false); // Close the dialog after successful update
                    fetchData(); // Reload the student data
                } else {
                    console.error('Update failed:', response.data.message || 'Unknown error');
                }
            })
            .catch(error => {
                console.error('Error updating status:', error.response?.data?.message || error.message || 'Unknown error');
            })
            .finally(() => {
                setLoading(false); // Stop loading after update
            });
    };


    useEffect(() => {
        fetchData();
        fetchDropdownOptions();
    }, []);

    const fetchData = async () => {
        setLoading(true); // Start loading
        try {
            // Start fetching data concurrently
            const [studentsResponse, coursesResponse, batchesResponse, feesResponse, certificatesResponse] = await Promise.all([
                axios.get(EndPoint.studentsApi),
                axios.get(EndPoint.getCourseApi),
                axios.get(EndPoint.getBatchApi),
                axios.get(EndPoint.getFeesApi),
                axios.get(EndPoint.getCertificatesApi),
            ]);

            // Handle each response and check if they are arrays
            const students = Array.isArray(studentsResponse.data) ? studentsResponse.data : [];
            const courses = Array.isArray(coursesResponse.data) ? coursesResponse.data : [];
            const batches = Array.isArray(batchesResponse.data) ? batchesResponse.data : [];
            const fees = Array.isArray(feesResponse.data) ? feesResponse.data : [];
            const certificates = Array.isArray(certificatesResponse.data) ? certificatesResponse.data : [];

            // Log warnings if any response is empty
            if (students.length === 0) {
                console.warn('No students found.');
            }
            if (courses.length === 0) {
                console.warn('No courses found.');
            }
            if (batches.length === 0) {
                console.warn('No batches found.');
            }
            if (fees.length === 0) {
                console.warn('No fees found.');
            }
            if (certificates.length === 0) {
                console.warn('No certificates found.');
            }

            // Update state with fetched data
            setStudentList(students);
            setCoursesList(courses);
            setBatchList(batches);
            setFeeList(fees);

        } catch (error) {
            console.error('Error fetching data:', error.message || error);
            // Optionally set an error state to display an error message in the UI
            // setError('Failed to fetch data. Please try again later.');
        } finally {
            setLoading(false); // Stop loading after data is fetched
        }
    };


    const handleStudentSelect = (stuLgId) => {
        setSelectedStuLgId(stuLgId);
    };

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedImage('');

        setShowAddFeeModal(false);
        setFeeAmount('');
        setPaymentMethod('');
        setSelectedOption('');
        setPaymentDate('');
    };

    const handleAdmissionDateChange = (e) => {
        setAdmissionDate(e.target.value);
        setSearchQuery('');
        setGenderFilter('All');
    };

    const handleGenderFilterChange = (e) => {
        setGenderFilter(e.target.value);
        setSearchQuery('');
        setAdmissionDate('');
    };

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
        setAdmissionDate('');
        setGenderFilter('All');
    };

    const filteredStudents = admissionDate
        ? studentList.filter(student => {
            const admissionDateObj = new Date(student.stu_adm_date);
            return (
                admissionDateObj.getFullYear() === new Date(admissionDate).getFullYear() &&
                admissionDateObj.getMonth() === new Date(admissionDate).getMonth() &&
                admissionDateObj.getDate() === new Date(admissionDate).getDate()
            );
        })
        : studentList;

    const genderFilteredStudents = filteredStudents.filter(student => {
        if (genderFilter === 'All') return true;
        return student.stu_gender === genderFilter;
    });

    const searchFilteredStudents = genderFilteredStudents.filter(student =>
        student.stu_lg_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.stu_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const sortedStudents = searchFilteredStudents.sort((b, a) => new Date(a.stu_adm_date) - new Date(b.stu_adm_date));
    const sortedActiveStudents = searchFilteredStudents.filter(student => student.stu_status === '0');
    const sortedInActiveStudents = searchFilteredStudents.filter(student => student.stu_status === '1');
    const sortedSuspendedStudents = searchFilteredStudents.filter(student => student.stu_status === '2');
    const sortedCompleteStudents = searchFilteredStudents.filter(student => student.stu_status === '3');
    const sortedTransferredStudents = searchFilteredStudents.filter(student => student.stu_status === '4');

    const totalStudentsCount = sortedStudents.length;
    const totalActiveStudentsCount = sortedActiveStudents.length;
    const totalInActiveStudentsCount = sortedInActiveStudents.length;
    const totalSuspendedStudentsCount = sortedSuspendedStudents.length;
    const totalCompleteStudentsCount = sortedCompleteStudents.length;
    const totalTransferredStudentsCount = sortedTransferredStudents.length;

    const getStatusName = (status) => {
        switch (status) {
            case '0':
                return 'Active';
            case '1':
                return 'Inactive';
            case '2':
                return 'Suspended';
            case '3':
                return 'Complete';
            case '4':
                return 'Transferred';
            default:
                return 'Unknown';
        }
    };

    // Fetch dropdown options from the API
    const fetchDropdownOptions = async () => {
        try {
            const response = await axios.get(EndPoint.getMemberApi); // Replace with your actual dropdown API endpoint
            setDropdownOptions(response.data);
        } catch (err) {
            console.error('Error fetching dropdown options:', err);
        }
    };


    const handleFeeSubmit = async () => {
        let hasError = false;
        

        // Reset error messages
        setFeeAmountError('');
        setPaymentMethodError('');
        setSelectedOptionError('');
        setPaymentDateError('');

        // Validate Fee Amount
        if (!feeAmount) {
            setFeeAmountError('Fee amount is required');
            hasError = true;
        }

        // Validate Payment Method
        if (!paymentMethod) {
            setPaymentMethodError('Payment type is required');
            hasError = true;
        }

        // Validate Teacher Selection
        if (!selectedOption) {
            setSelectedOptionError('Teacher selection is required');
            hasError = true;
        }

        // Validate Payment Date
        if (!paymentDate) {
            setPaymentDateError('Payment date is required');
            hasError = true;
        }

        // If there's any error, stop the submission
        if (hasError) {
            return;
        }

        // Create a FormData object and proceed with the submission if no errors
        const formData = new FormData();
        formData.append('stu_lg_id', selectedStuLgId);
        formData.append('amount', feeAmount);
        formData.append('fee_type', paymentMethod);
        formData.append('teacher_lg_id', selectedOption);
        formData.append('fee_date', paymentDate);

        try {
            const response = await axios.post(EndPoint.insertFeesApi, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                console.log('Fee submitted successfully:', response.data);
                const updatedFeesData = await axios.get(EndPoint.getFeesApi);
                setFeeList(updatedFeesData.data);
                fetchData();
            } else {
                console.error('Error submitting fee:', response);
            }
        } catch (err) {
            console.error('Error submitting fee:', err);
        }

        console.log('Fee submitted:', formData);
        handleCloseModal();
    };



    // Handle click on "Add Fee" button
    const handleAddFeeClick = (stu_lg_id) => {
        setShowAddFeeModal(true);
    };


    const userDetailsWidgets = () => {
        if (!selectedStuLgId) {
            return (
                <div className="text-center my-1">
                    <h4>Select a student to view details</h4>
                </div>
            );
        } else {
            const selectedStudent = studentList.find(stu => stu.stu_lg_id === selectedStuLgId);
            const courseSelected = coursesList.find(course => course.course_id === selectedStudent.stu_course);
            const batchSelected = batchList.find(batch => batch.batch_id === selectedStudent.stu_batch_id);
            const fees = feeList.filter(fee => fee.stu_lg_id === selectedStuLgId);
            const totalPaidAmount = fees.reduce((sum, fee) => sum + parseFloat(fee.amount), 0.0);

            const remainingAmount = courseSelected
                ? (parseFloat(courseSelected.course_price) - totalPaidAmount).toFixed(2)
                : "N/A"; // If no course is found, show N/A


            return (
                <div style={{ padding: '10px', height: '70vh', overflowY: 'auto' }}>
                    <div className='d-flex justify-content-between' style={{ marginBlock: '10px' }}>
                        <h4 className="mb-3">Student Details</h4>
                        <div>
                            <Button className='me-2' onClick={() => handleAddFeeClick(selectedStudent)}>Add Fee</Button>
                            <Button className='me-2' onClick={handleEditClick}>Edit Details</Button>
                        </div>
                    </div>

                    {/* Modal to add a new fee */}

                    <Modal show={showAddFeeModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Fee for Student {selectedStudent.stu_name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group controlId="feeAmount">
                                    <Form.Label>Fee Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter fee amount"
                                        value={feeAmount}
                                        onChange={(e) => setFeeAmount(e.target.value)}
                                        isInvalid={!!feeAmountError}  // Show red border if error exists
                                    />
                                    {feeAmountError && <Form.Text className="text-danger">{feeAmountError}</Form.Text>}
                                </Form.Group>

                                <Form.Group controlId="paymentMethod">
                                    <Form.Label>Payment Type</Form.Label>
                                    <Form.Select
                                        value={paymentMethod}
                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                        isInvalid={!!paymentMethodError}  // Show red border if error exists
                                    >
                                        <option value="">Select Payment Type</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Online">Online</option>
                                        <option value="Concession">Concession</option>
                                        <option value="Discount">Discount</option>
                                    </Form.Select>
                                    {paymentMethodError && <Form.Text className="text-danger">{paymentMethodError}</Form.Text>}
                                </Form.Group>

                                <Form.Group controlId="dropdown">
                                    <Form.Label>Select Teacher</Form.Label>
                                    <Form.Select
                                        value={selectedOption}
                                        onChange={(e) => setSelectedOption(e.target.value)}
                                        isInvalid={!!selectedOptionError}  // Show red border if error exists
                                    >
                                        <option value="">Select an option</option>
                                        {dropdownOptions.map((option) => (
                                            <option value={option.lg_id} key={option.lg_id}>
                                                {option.fullName}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {selectedOptionError && <Form.Text className="text-danger">{selectedOptionError}</Form.Text>}
                                </Form.Group>

                                <Form.Group controlId="paymentDate">
                                    <Form.Label>Payment Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={paymentDate}
                                        onChange={(e) => setPaymentDate(e.target.value)}
                                        isInvalid={!!paymentDateError}  // Show red border if error exists
                                    />
                                    {paymentDateError && <Form.Text className="text-danger">{paymentDateError}</Form.Text>}
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleFeeSubmit}>
                                Submit Fee
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/* Modal for editing details */}
                    <Modal show={showEditDialog} onHide={() => setShowEditDialog(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Student Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group controlId="lgId">
                                    <Form.Label>LG ID</Form.Label>
                                    <Form.Control type="text" readOnly value={selectedStudent.stu_lg_id} />
                                </Form.Group>
                                <Form.Group controlId="status" className="mt-3">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control as="select" value={selectedStatus} onChange={handleStatusChange}>
                                        <option value="0">Active</option>
                                        <option value="1">Inactive</option>
                                        <option value="2">Suspend</option>
                                        <option value="3">Complete</option>
                                        <option value="4">Transferred</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowEditDialog(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleUpdateDetails}>
                                Update
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    <Card className="shadow-sm mb-4">
                        <Card.Body className="d-flex align-items-center">
                            <img
                                src={selectedStudent.stu_img}
                                alt="Student"
                                className="student-detail-image"
                                onClick={() => handleImageClick(selectedStudent.stu_img)}
                                style={{ cursor: 'pointer' }}
                            />
                            <div className="ms-3">
                                <h5>{selectedStudent.stu_name}</h5>
                                <strong>LG ID:</strong> {selectedStudent.stu_lg_id}
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className="mb-4">
                        <Card.Header>Educational Details</Card.Header>
                        <Card.Body>
                            {selectedUserDetailView("Qualification: ", selectedStudent.stu_education)}
                            {selectedUserDetailView("Course Name: ", courseSelected ? courseSelected.course_name : "N/A")}
                            {selectedUserDetailView("Batch: ", batchSelected ? `${batchSelected.batch_name} | ${batchSelected.batch_start_time} to ${batchSelected.batch_end_time}` : "N/A")}
                            {selectedUserDetailView("Admission Date: ", format(new Date(selectedStudent.stu_adm_date), 'MMM-dd-yyyy'))}
                            {selectedUserDetailView("Status: ", getStatusName(selectedStudent.stu_status))}
                        </Card.Body>
                    </Card>

                    <Card className="mb-4">
                        <Card.Header>Personal Details</Card.Header>
                        <Card.Body>
                            {selectedUserDetailView("Full Name: ", selectedStudent.stu_name)}
                            {selectedUserDetailView("Father's Name: ", selectedStudent.stu_father_name)}
                            {selectedUserDetailView("Mother's Name: ", selectedStudent.stu_mother_name)}
                            {selectedUserDetailView("Gender: ", selectedStudent.stu_gender)}
                            {selectedUserDetailView("DOB: ", format(new Date(selectedStudent.stu_dob), 'MMM-dd-yyyy'))}
                            {selectedUserDetailView("Aadhaar No: ", selectedStudent.stu_adhar)}
                            {selectedUserDetailView("Mobile No: ", selectedStudent.stu_mo_no)}
                            {selectedUserDetailView("Address: ", selectedStudent.stu_address)}
                            {selectedUserDetailView("Nationality: ", selectedStudent.stu_nationality)}
                            {selectedUserDetailView("Religion: ", selectedStudent.stu_religion)}
                            {selectedUserDetailView("Category: ", selectedStudent.stu_category)}
                            {selectedUserDetailView("Pin Code: ", selectedStudent.stu_pin_code)}
                        </Card.Body>
                    </Card>

                    <h5 className="mb-3">Fee Details</h5>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Payment Date</th>
                                <th>Payment Type</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fees.length === 0 ? (
                                <tr>
                                    <td colSpan="4" className="text-center">No Records Found</td>
                                </tr>
                            ) : (
                                fees.map((fee, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{format(new Date(fee.fee_date), 'MMM-dd-yyyy')}</td>
                                        <td>{fee.fee_type}</td>
                                        <td>{`${fee.amount}.00 Rs`}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>

                    <div className="total-fees">
                        <div>Total Paid Amount: {totalPaidAmount.toFixed(2)} Rs</div>
                        <div>Remaining Amount: {remainingAmount} Rs</div>
                    </div>
                </div>
            );
        }
    };


    const selectedUserDetailView = (label, value) => (
        <div><strong>{label}</strong> {value}</div>
    );

    const getStatusColor = (status) => {
        switch (status) {
            case '0': // Active
                return 'rgba(76, 175, 80, 0.2)'; // Light green
            case '1': // Inactive
                return 'rgba(255, 87, 34, 0.2)'; // Light orange
            case '2': // Suspend
                return 'rgba(255, 193, 7, 0.2)'; // Light yellow
            case '3': // Complete
                return 'rgba(63, 81, 181, 0.2)'; // Light blue
            case '4': // Transferred
                return 'rgba(233, 30, 99, 0.2)'; // Light pink
            default:
                return 'white'; // Default color
        }
    };

    return (
        <Container fluid style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>

            {loading && (
                <div className="text-center m-5">
                    <ProgressBar animated now={100} label="Loading..." />
                </div>
            )}

            {!loading && (
                <div>
                    <Row>
                        <Col>
                            {/* Filters Row */}
                            <Row className='my-2'>
                                <Col>
                                    <h6>Total: {totalStudentsCount}</h6>
                                </Col>
                                <Col>
                                    <h6>Active: {totalActiveStudentsCount}</h6>
                                </Col>
                                <Col>
                                    <h6>Inactive: {totalInActiveStudentsCount}</h6>
                                </Col>
                                <Col>
                                    <h6>Completed: {totalCompleteStudentsCount}</h6>
                                </Col>
                                <Col>
                                    <h6>Suspended: {totalSuspendedStudentsCount}</h6>
                                </Col>
                                <Col>
                                    <h6>Transferred: {totalTransferredStudentsCount}</h6>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}>
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Name or LG ID"
                                            value={searchQuery}
                                            // onChange={(e) => setSearchQuery(e.target.value)}
                                            onChange={handleSearchQueryChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={4}>
                                    {/* Admission Date Filter */}
                                    <Form.Group>
                                        <Form.Control
                                            type="date"
                                            value={admissionDate}
                                            onChange={handleAdmissionDateChange}
                                        // onChange={(e) => setAdmissionDate(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={4}>
                                    {/* Gender Filter */}
                                    <Form.Group>
                                        <Form.Control
                                            as="select"
                                            value={genderFilter}
                                            // onChange={(e) => setGenderFilter(e.target.value)}
                                            onChange={handleGenderFilterChange}
                                        >
                                            <option value="All">All</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4} className="sidebar">
                            <h5 className="mb-3">Students List</h5>
                            <Button variant="success" onClick={fetchData} disabled={loading}>
                                {loading ? 'Refreshing...' : 'Refresh'}
                            </Button>

                            {/* Rest of the student list */}
                            {sortedStudents.map(student => (
                                <Card className="student-item mb-2 mt-2" key={student.stu_lg_id} onClick={() => handleStudentSelect(student.stu_lg_id)}
                                    style={{ backgroundColor: getStatusColor(student.stu_status) }}
                                >
                                    <Card.Body className="d-flex align-items-center">
                                        <img
                                            src={student.stu_img}
                                            alt={student.stu_name}
                                            className="student-image"
                                            onClick={() => handleImageClick(student.stu_img)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <div className="ms-3">
                                            <h6 className="mb-0">{student.stu_name}</h6>
                                            <small>{student.stu_lg_id}</small>
                                        </div>
                                    </Card.Body>
                                </Card>
                            ))}
                        </Col>

                        <Col xs={12} md={8}>
                            {userDetailsWidgets()}
                        </Col>
                    </Row>
                </div>
            )}

            {/* Modal for Image Display */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Body>
                    <img src={selectedImage} alt="Selected" className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default Students;