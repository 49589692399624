import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import Login from './pages/auth/login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import HomePage from './pages/homepage';
import Students from './pages/students/students';
import AddStudent from './pages/students/add_student';
import StudentDashboard from './pages/students/student_dashboard';
import Fees from './pages/fees/fees';
import FeeSummary from './pages/fees/fee_summary';
import Course from './pages/course/course';
import PrivateRoute from './component/PrivateRoute'; // Import your PrivateRoute
import ViewCertificates from './certificates/view_certificates';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}>
          <Route index element={<Login />} />
          <Route path='homepage' element={<HomePage />}>
            <Route element={<PrivateRoute />}> {/* Wrap with PrivateRoute */}
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='students' element={<Students />} />
              <Route path='student-dashboard' element={<StudentDashboard />} />
              <Route path='add-student' element={<AddStudent />} />
              <Route path='fees-dashboard' element={<Fees />} />
              <Route path='courses' element={<Course />} />
              <Route path='fees-summary' element={<FeeSummary />} />
              <Route path='certificates' element={<ViewCertificates />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
