// src/auth.js
export const isAuthenticated = () => {
    // Check if token exists or any other authentication logic
    const token = localStorage.getItem("authToken");
    return token !== null;
};

export const logout = () => {
    localStorage.removeItem("authToken"); // Remove the token
    window.location.href = "/"; // Redirect to login page
};