// src/hooks/useInactiveLogout.js
import { useEffect } from 'react';
import { logout } from './auth'; // Adjust the import path as needed

const useInactiveLogout = (timeout = 7 * 60 * 1000) => {
    useEffect(() => {
        let timer;

        const resetTimer = () => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                logout(); // Call the logout function after timeout
            }, timeout);
        };

        // Add event listeners for user activity
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keypress', resetTimer);
        window.addEventListener('click', resetTimer);
        window.addEventListener('scroll', resetTimer);

        // Start the timer initially
        resetTimer();

        // Cleanup event listeners and timeout on unmount
        return () => {
            clearTimeout(timer);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keypress', resetTimer);
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('scroll', resetTimer);
        };
    }, [timeout]);
};

export default useInactiveLogout;