import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Spinner, Alert, Button } from 'react-bootstrap';
import { FaSync, FaEye, FaEyeSlash } from 'react-icons/fa'; // Importing icons
import axios from 'axios';
import EndPoint from '../../api/end_point'; // Update this to your actual endpoint

const FeeSummary = () => {
    const [feeData, setFeeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [showTotalFee, setShowTotalFee] = useState(false); // State to control visibility of total fee
    const [timeoutId, setTimeoutId] = useState(null); // To store timeout ID for clearing later

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    useEffect(() => {
        fetchFeeData();
    }, []);

    const fetchFeeData = async () => {
        setLoading(true); // Set loading state before fetching
        try {
            const response = await axios.get(EndPoint.getFeesApi); // Replace with your API endpoint
            setFeeData(response.data);
            setError(''); // Clear any previous errors
        } catch (err) {
            setError('Error fetching fee data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    // Function to aggregate fees by month and year
    const aggregateFeesByMonth = () => {
        const monthFees = {};
        let totalYearFees = 0; // Initialize total fees for the selected year

        // Initialize monthFees object with months as keys for the selected year
        months.forEach(month => {
            monthFees[`${month} ${selectedYear}`] = 0; // Initialize each month with 0 for the selected year
        });

        // Aggregate fees based on fee_date
        feeData.forEach(item => {
            const feeDate = new Date(item.fee_date);
            const monthIndex = feeDate.getMonth(); // Get month index (0-11)
            const year = feeDate.getFullYear(); // Get the year
            const monthName = months[monthIndex]; // Map index to month name
            const key = `${monthName} ${year}`; // Create a key like "April 2024"

            // Accumulate total fee collected for the corresponding month and year
            if (year === selectedYear) {
                const amount = Number(item.amount); // Ensure `item.amount` is the correct field
                monthFees[key] += amount;
                totalYearFees += amount; // Add to total year fees
            }
        });

        return { monthFees, totalYearFees };
    };

    const { monthFees, totalYearFees } = aggregateFeesByMonth();

    useEffect(() => {
        if (showTotalFee) {
            // Hide the total fee after 6 seconds
            const id = setTimeout(() => {
                setShowTotalFee(false);
            }, 6000);
            setTimeoutId(id); // Store the timeout ID for clearing later
        }

        // Clear timeout if component unmounts or if showTotalFee changes
        return () => clearTimeout(timeoutId);
    }, [showTotalFee]);

    const toggleTotalFeeVisibility = () => {
        setShowTotalFee(true); // Show the total fee
        clearTimeout(timeoutId); // Clear previous timeout if it exists
        // Automatically hide after 6 seconds
        const id = setTimeout(() => {
            setShowTotalFee(false);
        }, 6000);
        setTimeoutId(id); // Store the new timeout ID
    };

    if (loading) {
        return (
            <Container className="text-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <Container>
            <h2 className="my-4">Fee Summary</h2>

            {/* Year Filter */}
            <Form.Group controlId="yearFilter" className='mb-3'>
                <Form.Label>Select Year</Form.Label>
                <Form.Control
                    as="select"
                    value={selectedYear}
                    onChange={e => {
                        setSelectedYear(Number(e.target.value)); // Ensure the selected year is a number
                        fetchFeeData(); // Fetch data for the new selected year
                    }}
                >
                    {/* Display the last few years */}
                    {[...Array(5)].map((_, index) => {
                        const year = new Date().getFullYear() - index;
                        return (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        );
                    })}
                </Form.Control>
            </Form.Group>

            {/* Total Fee Collected for the Selected Year */}
            <Button onClick={toggleTotalFeeVisibility} variant="outline-info" className="mb-4 me-2">
                {showTotalFee ? <FaEyeSlash /> : <FaEye />} Show Collection
            </Button>

            {showTotalFee && (
                <h4 className="mb-4">
                    Total Fee Collected for {selectedYear}: ₹{totalYearFees || 0}
                </h4>
            )}

            {/* Refresh Data Button */}
            <Button onClick={fetchFeeData} variant="outline-primary" className="mb-4">
                <FaSync /> Refresh Data
            </Button>

            {/* Fee Summary Tiles */}
            <Row>
                {months.map((month) => (
                    <Col key={month} md={4} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>{`${month} ${selectedYear}`}</Card.Title>
                                <Card.Text>
                                    Total Fee Collected: ₹{monthFees[`${month} ${selectedYear}`] || 0}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default FeeSummary;