import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Modal, Button, Table, Spinner } from "react-bootstrap"; // Import Spinner
import axios from "axios";
import EndPoint from '../../api/end_point';
import './student_dashboard.css';

const StudentDashboard = () => {
    const [students, setStudents] = useState([]);
    const [stats, setStats] = useState({
        totalStudents: 0,
        maleStudents: 0,
        femaleStudents: 0,
        enrolledThisMonth: 0,
        activeStudent: 0,
        InactiveStudent: 0,
        completedStudent: 0,
    });
    const [showModal, setShowModal] = useState(false); // Modal visibility
    const [selectedStudent, setSelectedStudent] = useState(null); // Selected student details
    const [loading, setLoading] = useState(true); // Loading state

    // Helper function to check if the fee was paid in the last 30 days
    const isFeePaidLastThirtyDays = (feeDate) => {
        const paymentDate = new Date(feeDate);
        const currentDate = new Date();
        const thirtyDaysAgo = new Date(currentDate);
        thirtyDaysAgo.setDate(currentDate.getDate() - 30);

        return paymentDate >= thirtyDaysAgo && paymentDate <= currentDate;
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Set loading to true before fetching data
            try {
                const studentsResponse = await axios.get(EndPoint.studentsApi);
                const studentsData = studentsResponse.data;

                const courseResponse = await axios.get(EndPoint.getCourseApi);
                const courseData = courseResponse.data;

                const feesResponse = await axios.get(EndPoint.getFeesApi);
                const feesData = feesResponse.data;

                // Map through students and check for fee status
                const updatedStudents = studentsData.map((student) => {
                    const matchingFees = feesData.filter((fee) => fee.stu_lg_id === student.stu_lg_id);
                    const hasPaidFees = matchingFees.some((fee) =>
                        isFeePaidLastThirtyDays(fee.fee_date)
                    );

                    // Get course name
                    const studentCourse = courseData.find(course => course.course_id === student.stu_course);

                    return {
                        ...student,
                        feeStatus: hasPaidFees ? "Paid" : "Unpaid",
                        course_name: studentCourse ? studentCourse.course_name : "N/A", // Add course name or "N/A"
                    };
                });

                // Sort students by admission date
                const sortedStudents = updatedStudents.sort((a, b) => {
                    return new Date(a.stu_adm_date) - new Date(b.stu_adm_date);
                });

                setStudents(sortedStudents);

                // Calculate statistics
                const total = updatedStudents.length;
                const males = updatedStudents.filter((student) => student.stu_gender === "Male").length;
                const females = updatedStudents.filter((student) => student.stu_gender === "Female").length;
                const active = updatedStudents.filter((student) => student.stu_status === "0").length;
                const inActive = updatedStudents.filter((student) => student.stu_status === "1").length;
                // const suspended = updatedStudents.filter((student) => student.stu_status === "2").length;
                const completed = updatedStudents.filter((student) => student.stu_status === "3").length;
                const currentDate = new Date();
                const enrolledThisMonth = updatedStudents.filter(
                    (student) => {
                        const admissionDate = new Date(student.stu_adm_date);
                        return (
                            admissionDate.getMonth() === currentDate.getMonth() &&
                            admissionDate.getFullYear() === currentDate.getFullYear()
                        );
                    }
                ).length;

                setStats({
                    totalStudents: total,
                    maleStudents: males,
                    femaleStudents: females,
                    enrolledThisMonth: enrolledThisMonth,
                    activeStudent: active,
                    InactiveStudent: inActive,
                    completedStudent: completed,
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };

        fetchData();
    }, []);

    // Function to handle card click
    const handleCardClick = (student) => {
        setSelectedStudent(student);
        setShowModal(true); // Show modal
    };

    // Function to handle modal close
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedStudent(null); // Clear selected student
    };

    return (
        <Container fluid className="px-2">
            {/* Show loading spinner when data is being fetched */}
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
                    <Spinner animation="border" variant="primary" /> {/* Bootstrap Spinner */}
                </div>
            ) : (
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Total Students</th>
                                <th>Male Students</th>
                                <th>Female Students</th>
                                <th>Enrolled This Month</th>
                                <th>Active</th>
                                <th>Inactive</th>
                                <th>Completed</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{stats.totalStudents}</td>
                                <td>{stats.maleStudents}</td>
                                <td>{stats.femaleStudents}</td>
                                <td>{stats.enrolledThisMonth}</td>
                                <td>{stats.activeStudent}</td>
                                <td>{stats.InactiveStudent}</td>
                                <td>{stats.completedStudent}</td>
                            </tr>
                        </tbody>
                    </Table>

                    <Row className="mt-4">
                        <Col style={{ height: '70vh', overflowY: 'auto' }}>
                            <h3>Student Details</h3>
                            <Row>
                                {students.slice().reverse().map((student) => (
                                    <Col xs={12} sm={6} md={4} lg={3} key={student.id} className="mb-4">
                                        <Card
                                            style={{ cursor: 'pointer' }}
                                            className={`student-card ${student.feeStatus === "Paid" ? 'paid' : 'unpaid'}`}
                                            onClick={() => handleCardClick(student)} // Handle click
                                        >
                                            <Card.Body>
                                                <div className="card-image-container">
                                                    <Card.Img variant="top" src={student.stu_img} alt="student" />
                                                </div>
                                                <Card.Title className="student-name">{student.stu_name}</Card.Title>
                                                <Card.Text>
                                                    <strong>LG ID:</strong> {student.stu_lg_id}<br />
                                                    <strong>Gender:</strong> {student.stu_gender}<br />
                                                    <strong>Fee Status:</strong> {student.feeStatus}<br />
                                                    <strong>Course:</strong> {student.course_name}<br />
                                                    <strong>Enrolled:</strong> {new Date(student.stu_adm_date).toLocaleDateString()}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>

                    {/* Modal for displaying student details */}
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Student Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedStudent && (
                                <Table striped bordered hover>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2} className="text-center">
                                                <div className="card-image-container">
                                                    <Card.Img variant="top" src={selectedStudent.stu_img} alt="student" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Name:</strong></td>
                                            <td>{selectedStudent.stu_name}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>LG ID:</strong></td>
                                            <td>{selectedStudent.stu_lg_id}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Gender:</strong></td>
                                            <td>{selectedStudent.stu_gender}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Fee Status:</strong></td>
                                            <td>{selectedStudent.feeStatus}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Course:</strong></td>
                                            <td>{selectedStudent.course_name}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Enrolled Date:</strong></td>
                                            <td>{new Date(selectedStudent.stu_adm_date).toLocaleDateString()}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )}
        </Container>
    );
};

export default StudentDashboard;