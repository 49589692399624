// src/hooks/useInactiveLogoutWithWarning.js
import { useEffect, useState } from 'react';
import { logout } from './auth'; // Adjust the import path as needed

const useInactiveLogoutWithWarning = (timeout = 7 * 60 * 1000) => {
    const [remainingTime, setRemainingTime] = useState(timeout); // State for remaining time
    let inactivityTimer;

    const resetTimers = () => {
        // Clear existing timer
        clearTimeout(inactivityTimer);
        setRemainingTime(timeout); // Reset remaining time

        // Set the inactivity timer
        inactivityTimer = setTimeout(() => {
            logout(); // Call the logout function after timeout
        }, timeout);
    };

    useEffect(() => {
        const tick = () => {
            setRemainingTime(prevTime => Math.max(prevTime - 1000, 0)); // Decrease remaining time
        };

        // Update the timer every second
        const interval = setInterval(tick, 1000);
        
        // Add event listeners for user activity
        window.addEventListener('mousemove', resetTimers);
        window.addEventListener('keypress', resetTimers);
        window.addEventListener('click', resetTimers);
        window.addEventListener('scroll', resetTimers);

        // Start the timers initially
        resetTimers();

        // Cleanup event listeners, timers, and intervals on unmount
        return () => {
            clearTimeout(inactivityTimer);
            clearInterval(interval); // Clear the interval
            window.removeEventListener('mousemove', resetTimers);
            window.removeEventListener('keypress', resetTimers);
            window.removeEventListener('click', resetTimers);
            window.removeEventListener('scroll', resetTimers);
        };
    }, [timeout]);

    return remainingTime; // Return the remaining time
};

export default useInactiveLogoutWithWarning;