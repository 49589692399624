import React, { useState } from 'react';
import { Container, Row, Col, Navbar, ListGroup } from 'react-bootstrap';
import { NavLink, Outlet } from 'react-router-dom';
import useInactiveLogout from '../component/userInactiveLogout';
import useInactiveLogoutWithWarning from '../component/useInactiveLogoutWithWarning';

const HomePage = () => {
    const dateTime = new Date();
    const startDate = new Date(dateTime.getFullYear(), 3, 1);

    useInactiveLogout(7 * 60 * 1000);

    const remainingTime = useInactiveLogoutWithWarning(7 * 60 * 1000);

    const formatTime = (time) => {
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const seconds = Math.floor((time / 1000) % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return (
        <Container fluid>
            <Navbar bg="primary" variant="dark" expand="lg" className="px-3" style={{ height: '11vh' }}>
                <Navbar.Brand style={{ fontSize: '2em', fontWeight: '600' }}>Life Guidance Computer Institute</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text className="text-white">
                        Session {startDate.getFullYear()} - {startDate.getFullYear() + 1}
                    </Navbar.Text>
                    <Navbar.Text className="text-white">
                        You will be logged out in: {formatTime(remainingTime)}
                    </Navbar.Text>
                </Navbar.Collapse>
            </Navbar>
            <Row className="mt-4">
                {/* Responsive column for navigation drawer */}
                <Col xs={12} md={3} className="mb-3">
                    <NavigationDrawer />
                </Col>
                {/* Responsive content area */}
                <Col xs={12} md={9} style={{ height: '85vh', overflowY: 'auto', paddingInline: '2%' }}>
                    <Outlet />
                </Col>
            </Row>
        </Container>
    );
};

const NavigationDrawer = () => {
    const [expandedMenus, setExpandedMenus] = useState({});

    const toggleSubMenu = (index) => {
        setExpandedMenus((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const menuItems = [
        { icon: 'fas fa-tachometer-alt', text: 'Dashboard', route: 'dashboard' },
        {
            text: 'Student Dashboard',
            route: 'student-dashboard',
            icon: 'fas fa-users',
            subMenu: [
                { text: 'Students', route: 'students' },
                { text: 'Add Student', route: 'add-student' },
            ],
        },
        {
            icon: 'fas fa-rupee-sign', text: 'Fees Dashboard', route: 'fees-dashboard'
        },
        {
            icon: 'fas fa-rupee-sign', text: 'View Certificates', route: 'certificates'
        },
    ];

    return (
        <ListGroup as="ul">
            {menuItems.map((item, index) => (
                <div key={index}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <NavLink
                            to={item.route}
                            className={({ isActive }) =>
                                isActive ? 'bg-primary text-white' : ''
                            }
                            style={{ textDecoration: 'none', flex: '1' }}
                        >
                            {({ isActive }) => (
                                <ListGroup.Item
                                    as="li"
                                    action
                                    className={isActive ? 'bg-primary text-white' : 'text-black'}
                                >
                                    <i className={`${item.icon} mr-2`}></i> {item.text}
                                </ListGroup.Item>
                            )}
                        </NavLink>

                        {item.subMenu && (
                            <i
                                className={`fas ml-2 mx-3 ${expandedMenus[index] ? 'fa-chevron-up' : 'fa-chevron-down'
                                    }`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => toggleSubMenu(index)}
                            />
                        )}
                    </div>

                    {item.subMenu && expandedMenus[index] && (
                        <ListGroup as="ol" className="ml-4 mt-2 ms-4 mb-2">
                            {item.subMenu.map((subItem, subIndex) => (
                                <NavLink
                                    to={subItem.route}
                                    key={subIndex}
                                    className={({ isActive }) =>
                                        isActive ? 'bg-primary text-white' : ''
                                    }
                                    style={{ textDecoration: 'none' }}
                                >
                                    {({ isActive }) => (
                                        <ListGroup.Item
                                            as="li"
                                            action
                                            className={isActive ? 'bg-primary text-white' : 'text-black'}
                                        >
                                            {subItem.text}
                                        </ListGroup.Item>
                                    )}
                                </NavLink>
                            ))}
                        </ListGroup>
                    )}
                </div>
            ))}
        </ListGroup>
    );
};

export default HomePage;